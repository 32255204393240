// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Layout from "../shared/Layout.re.mjs";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as JsxRuntime from "react/jsx-runtime";

import { t } from '@lingui/macro'
;

function NotFound(props) {
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsx(Layout.Container.make, {
                              children: t`page not found.`
                            });
                })
            });
}

var make = NotFound;

export {
  make ,
}
/*  Not a pure module */
